import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import GatsbyLink from "gatsby-link";
import PageWrapper from "components/PageWrapper";
import log from "utils/log";
import RichText from "components/RichText";
import Logo from "components/Logo";
import DogCard from "components/DogCard";
import HollowCircularButton from "components/HollowCircularButton";
import SolidCircularButton from "components/SolidCircularButton";
import * as coreTheme from "@bit/cpinnix.verious.theme";
import * as siteTheme from "theme";
import * as heroClasses from "./hero.module.css";

export const query = graphql`
  query {
    descriptionImage: contentfulAsset(
      contentful_id: { eq: "4MO4JDc5UBW9bUV9oR5DaR" }
    ) {
      id
      fixed {
        src
      }
    }
    heroImage: contentfulAsset(
      contentful_id: { eq: "4MO4JDc5UBW9bUV9oR5DaR" }
    ) {
      id
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    heroAdopt: allContentfulText(
      filter: { contentful_id: { eq: "57ZsCSVPJti38S7kE5Kiac" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    heroDonate: allContentfulText(
      filter: { contentful_id: { eq: "5qnlGHpt9zbMp7OyUPzxSV" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    homeIntro: allContentfulText(
      filter: { contentful_id: { eq: "1zP9uw4BsBWlu9G7MolHdw" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    nanaLogo: allContentfulAsset(
      filter: { contentful_id: { eq: "1Z0h9f31lTykm19RcnZerZ" } }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 2048) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    nanaImage: allContentfulAsset(
      filter: { contentful_id: { eq: "4MO4JDc5UBW9bUV9oR5DaR" } }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 2048) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    testimonials: allContentfulHappyTail(limit: 1) {
      edges {
        node {
          id
          headline
          author
          teaser
          name
          teaserImage {
            fluid(maxWidth: 2048) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    dogs: allContentfulAdoptDog {
      edges {
        node {
          id
          name
          sex
          weight
          breed
          adopted
          age
          adoptionLink
          picture {
            fluid(maxHeight: 640, maxWidth: 480, resizingBehavior: THUMB) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

function present(data) {
  const seo = {
    title: "Home",
    image: data?.descriptionImage?.fixed?.src,
    description:
      "Nana's Haven in St. Petersburg, FL offers support and services for neglected and abused animals. Learn more about us today.",
  };

  const presentation = {
    seo,
    heroAdopt: data?.heroAdopt?.nodes[0]?.text,
    heroDonate: data?.heroDonate?.nodes[0]?.text,
    homeIntro: data?.homeIntro?.nodes[0]?.text,
    nanaImage: data?.nanaImage?.edges[0]?.node,
    nanaLogo: data?.nanaLogo?.edges[0]?.node,
    adoptableDogs: data?.dogs?.edges
      .map((edge) => edge.node)
      .filter(({ adopted }) => !adopted)
      .splice(0, 3),
    testimonial: data?.testimonials?.edges.map((edge) => edge.node)[0],
    heroImage: data?.heroImage?.fluid,
  };

  log("[PAGE] presentation", presentation);

  return presentation;
}

export default function Page({ data }) {
  const {
    seo,
    adoptableDogs,
    descriptionImage,
    heroAdopt,
    heroDonate,
    heroImage,
    homeIntro,
  } = present(data);

  return (
    <PageWrapper image={descriptionImage} seo={seo}>
      {/* Hero ---> */}
      <div className="container mx-auto px-8 md:px-16">
        <div className={heroClasses.heroLayout}>
          <div className={heroClasses.heroText}>
            <div className={heroClasses.heroTextLayout}>
              <h1 className={heroClasses.heroTitle}>Adopt Today</h1>
              <div className={heroClasses.heroContent}>
                <p className={heroClasses.heroBlurb}>
                  Rescued from dog meat farms, overcrowded and high-kill
                  shelters, or the streets, the dogs that have come to Nana's
                  Haven all have their own unique stories to tell. Meet the dogs
                  who are looking for their forever homes.
                </p>
                <GatsbyLink to="/adopt/dogs">
                  <SolidCircularButton
                    theme={{
                      backgroundColor: siteTheme.button.primary,
                      color: coreTheme.white,
                    }}
                  >
                    See Adoptable Dogs
                  </SolidCircularButton>
                </GatsbyLink>
              </div>
            </div>
          </div>
          <div className={heroClasses.heroImage}>
            <GatsbyImage fluid={heroImage} />
          </div>
        </div>
      </div>
      {/* <---- Hero */}

      {/* Adoptable Dogs ---> */}
      <div className="bg-pink-200 py-32">
        <div className="container mx-auto px-8 md:px-16">
          <h2 className="text-4xl font-bold tracking-tight font-display mb-8 text-custom-slate">
            Our Adoptable Dogs
          </h2>
        </div>
        <div className="container mx-auto sm:px-8 md:px-16">
          <div className="sm:grid sm:gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {adoptableDogs.map((dog) => (
              <DogCard
                key={dog.id}
                name={dog.name}
                sex={dog.sex}
                age={dog.age}
                Image={
                  <GatsbyLink to={`/dog/${dog.id}`}>
                    <GatsbyImage fluid={dog.picture[0].fluid} />
                  </GatsbyLink>
                }
                LearnMoreCallToAction={
                  <GatsbyLink to={`/dog/${dog.id}`}>
                    <HollowCircularButton
                      wide
                      theme={{
                        borderColor: coreTheme.gray[500],
                        color: coreTheme.gray[600],
                      }}
                    >
                      Learn More
                    </HollowCircularButton>
                  </GatsbyLink>
                }
                AdoptionCallToAction={
                  dog.adoptionLink && (
                    <a href={dog.adoptionLink} target="_blank" rel="noreferrer">
                      <SolidCircularButton
                        wide
                        theme={{
                          backgroundColor: siteTheme.button.primary,
                          color: coreTheme.white,
                        }}
                      >
                        Adopt Me
                      </SolidCircularButton>
                    </a>
                  )
                }
                theme={{
                  wrapper: {
                    backgroundColor: coreTheme.white,
                  },
                  adopted: {
                    color: coreTheme.white,
                    backgroundColor: "#f27d77",
                  },
                }}
              />
            ))}
          </div>
        </div>
        <div className="container mx-auto px-8 md:px-16 mt-8">
          <div className="text-center grid gap-4 sm:flex sm:items-center sm:justify-end">
            <div className="mr-4">Be a forever home.</div>
            <GatsbyLink to="/adopt/dogs">
              <HollowCircularButton
                wide
                theme={{
                  borderColor: coreTheme.black,
                  color: coreTheme.black,
                }}
              >
                See Adoptable Dogs
              </HollowCircularButton>
            </GatsbyLink>
          </div>
        </div>
      </div>
      {/* <---- Adoptable Dogs */}
      {/* About ----> */}
      <div>
        <div className="container mx-auto px-8 md:px-16 py-32">
          <div className="grid gap-12 lg:gap-8 lg:grid-cols-12">
            <div className="lg:col-span-6">
              <div className="hidden lg:block xl:hidden">
                <div className="h-12" />
                <Logo size={320} />
              </div>
              <div className="hidden xl:block">
                <Logo size={480} />
              </div>
              <div className="lg:hidden">
                <Logo size={240} />
              </div>
            </div>
            <div className="lg:col-span-5">
              <RichText
                json={homeIntro}
                theme={{
                  title: {
                    color: siteTheme.heading.primary,
                    fontFamily: siteTheme.font.primary,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <---- About */}
    </PageWrapper>
  );
}
