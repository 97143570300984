import React from "react";
import PropTypes from "prop-types";
import * as coreTheme from "@bit/cpinnix.verious.theme";

function createAppearance({ wide, theme }) {
  return {
    wrapper: {
      className: `
        focus:outline-none
        py-3
        px-6
        rounded-full
        border-2
        ${wide ? "w-full" : ""}
      `,
      style: {
        borderColor: theme.borderColor,
        color: theme.color,
      },
    },
  };
}

export default function HollowCircularButton(props) {
  const { children } = props;
  const appearance = createAppearance(props);
  return <button {...appearance.wrapper}>{children}</button>;
}

HollowCircularButton.defaultProps = {
  theme: {
    borderColor: coreTheme.black,
    color: coreTheme.black,
  },
};

HollowCircularButton.propsTypes = {
  children: PropTypes.node,
  theme: PropTypes.shape({
    borderColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
};
