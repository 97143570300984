import React from "react";
import PropTypes from "prop-types";

function createAppearance({ theme }) {
  return {
    wrapper: {
      // className: `
      //   md:shadow-lg
      //   md:rounded
      //   md:overflow-hidden
      // `,
      style: {
        backgroundColor: theme?.wrapper?.backgroundColor,
      },
    },
    content: {
      className: `
        p-8
        pb-16
        md:pb-8
      `,
    },
    image: {
      className: `
      `,
    },
    name: {
      className: `
        text-lg
      `,
    },
    information: {
      className: `
        h-8
      `,
    },
    callToAction: {
      className: `
        flex
        flex-col
        justify-between
        xl:flex-row
        mt-8
      `,
    },
    adopted: {
      className: `
        text-center
        py-2
        px-6
        bg-pink-500
      `,
      style: {
        color: theme?.adopted?.color,
      },
    },
  };
}

export default function DogCard(props) {
  const {
    Image,
    name,
    age,
    sex,
    adopted,
    LearnMoreCallToAction,
    AdoptionCallToAction,
  } = props;
  const appearance = createAppearance(props);

  return (
    <div {...appearance.wrapper}>
      <div {...appearance.image}>{Image}</div>
      {adopted && <div {...appearance.adopted}>Adopted!</div>}
      <div {...appearance.content}>
        <div {...appearance.name}>{name}</div>
        <div {...appearance.information}>
          {(sex || age) && [sex, age].filter((v) => v).join(" - ")}
        </div>
        <div {...appearance.callToAction}>
          {LearnMoreCallToAction}
          <div className="w-4 h-4" />
          {AdoptionCallToAction}
        </div>
      </div>
    </div>
  );
}

DogCard.propTypes = {
  theme: PropTypes.shape({
    wrapper: PropTypes.shape({
      backgroundColor: PropTypes.string.isRequired,
    }).isRequired,
    adopted: PropTypes.shape({
      color: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
